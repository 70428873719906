import { UseGeoJsonSource } from '@libero/mapbox/hooks/sources/useGeoJsonSource';
import { UseSelectionTool } from '@libero/mapbox/hooks/tools/useSelectionTool';
import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import {
  ACTIVE_POLYGON_LAYER,
  NEW_POLYGON_LAYER,
  POLYGON_LAYER,
} from '@libero/mapbox/types/layers';
import { DRAWING_SOURCE, GEOJSON_SOURCE } from '@libero/mapbox/types/sources';
import { Color } from '@libero/types/Color';
import { computed, watch } from 'vue';

export const usePolygonLayer = (
  mapbox: UseMapbox,
  geoJsonSource: UseGeoJsonSource,
  selectionTool?: UseSelectionTool,
  color?: Color,
): void => {
  const registerLayers = () => {
    mapbox.map.addLayer({
      id: POLYGON_LAYER,
      type: 'fill',
      source: GEOJSON_SOURCE,
      paint: {
        'fill-color': color || ['get', 'color'],
        'fill-opacity': ['case', ['==', ['get', 'count'], 0], 0.5, 0.25],
        'fill-outline-color': color || ['get', 'color'],
      },
      filter: ['==', '$type', 'Polygon'],
    });

    mapbox.map.addLayer({
      id: ACTIVE_POLYGON_LAYER,
      type: 'fill',
      source: GEOJSON_SOURCE,
      paint: {
        'fill-color': color || ['get', 'color'],
        'fill-opacity': 0.5,
        'fill-outline-color': color || ['get', 'color'],
      },
      filter: ['all', ['in', 'id', ''], ['==', '$type', 'Polygon']],
    });

    if (mapbox.draw) {
      mapbox.map.addLayer({
        id: NEW_POLYGON_LAYER,
        type: 'fill',
        source: DRAWING_SOURCE,
        paint: {
          'fill-color': Color.primary600,
          'fill-opacity': 0.25,
        },
        filter: ['==', '$type', 'Polygon'],
      });
    }
  };

  if (selectionTool) {
    const isSelectionMode = computed(() => selectionTool.isSelectionMode);

    const setSelectionPaint = () => {
      if (selectionTool.isSelectionMode) {
        mapbox.map.setPaintProperty(POLYGON_LAYER, 'fill-color', Color.gray400);
      } else {
        mapbox.map.setPaintProperty(POLYGON_LAYER, 'fill-color', color || ['get', 'color']);
      }
    };

    watch(isSelectionMode, setSelectionPaint);
    mapbox.onStyleLoad(setSelectionPaint);
  }

  geoJsonSource.onLoad(registerLayers);
};
